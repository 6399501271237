import React from 'react'
import HighlightText from './HighlightText'
import CTAbutton from './CTAbutton'
import know_your_progress  from "../../../assets/Images/Know_your_progress.png"
import compare_with_others from "../../../assets/Images/Compare_with_others.png"
import plan_your_lesson from "../../../assets/Images/Plan_your_lessons.png"

const LearningLanguageSection = () => {
  return (
		<div className="flex flex-col items-center gap-5 mt-[120px] mb-32">
			<div className="font-inter font-semibold text-4xl text-center">
				Your swiss knife for <HighlightText text={"learning any language"} />
			</div>
			<div className="text-center text-richblack-600 mx-auto text-base font-medium w-[70%]">
				Using spin making learning multiple languages easy. with 20+ languages
				realistic voice-over, progress tracking, custom schedule and more.
			</div>
			<div className="flex flex-row items-center justify-center mt-5 w-[65%] md:w-[100%]">
				<img
					src={know_your_progress}
					alt="KNowYourProgressImage"
					className="object-contain -mr-32 "
				/>
				<img
					src={compare_with_others}
					alt="KNowYourProgressImage"
					className="object-contain"
				/>
				<img
					src={plan_your_lesson}
					alt="KNowYourProgressImage"
					className="object-contain -ml-36"
				/>
			</div>
			<div className="w-fit">
				<CTAbutton active={true} linkto={"/signup"}>
					Learn More
				</CTAbutton>
			</div>
		</div>
	);
}

export default LearningLanguageSection