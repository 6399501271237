export const TokenMobNavbarLinks = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Catalog",
    // path: '/catalog',
  },
  {
    title: "About Us",
    path: "/about",
  },
  {
    title: "Contact Us",
    path: "/contact",
  },
  {
    title: "Dashboard",
    path: "/dashboard/my-profile",
  },
  {
    title: "Logout",
    // path: "/signup",
  },
];
